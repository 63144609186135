import PropTypes from 'prop-types'
import React from 'react'
import GetImgCaptionFormat from '../../utils/GetImgCaptionFormat'
import backgroundImg from '../backgrounds/hafltone4.png'
import CustomImg from '../global/CustomImg'
import FigCaption from '../global/FigCaption'
import LightboxImg from '../global/LightboxImg'
import styles from './StaggeredImages.module.scss'

const StaggeredImages = ({ accent, images }) => {
  if (!Array.isArray(images) || !images.length) {
    return (
      <div className={`pageMargin1Col`}>
        <p className={`pb40 textColorTamari`}>
          ERROR: No images found for staggered_images component
        </p>
      </div>
    )
  } else {
    return (
      <section
        className={`relPos`}
        style={{
          background: `center / 115% repeat-y url(${backgroundImg}), linear-gradient(#111718, #151C1E, #111718)`,
        }}
      >
        <div className={`${styles.parallaxGroup}`}>
          {images.map(({ localFile, alternativeText, caption, id }, index) => {
            let captionCreditData = GetImgCaptionFormat(caption)
            return (
              <div key={id} className={`${styles.gridContainer}`}>
                <figure className={`mb32 relPos ${styles.imgContainer}`}>
                  <LightboxImg
                    localFile={localFile}
                    alt={alternativeText}
                    captionData={captionCreditData}
                    aria-label="View fullscreen"
                  >
                    <CustomImg
                      localFile={localFile}
                      alt={alternativeText}
                      captionData={captionCreditData}
                      style={{ maxHeight: '70vh' }}
                      // className={`${accent} accentBar`}
                    />
                  </LightboxImg>
                  <FigCaption accent={accent} caption={captionCreditData} />
                </figure>
                <div className={`${styles.textContainer}`} />
              </div>
            )
          })}
        </div>
      </section>
    )
  }
}

StaggeredImages.propTypes = {
  accent: PropTypes.string,
  images: PropTypes.array,
  paragraphs: PropTypes.array,
}

export default StaggeredImages
