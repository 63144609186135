import React, { useState, Fragment } from 'react'
import CustomImg from './CustomImg'
import Lightbox from '../global/Lightbox'
import styles from './LightboxImg.module.scss'

const LightboxImg = ({ children, localFile, alt, captionData, style }) => {
  const [lightboxVisible, setLightboxVisible] = useState(false)

  // This is commented out and replaced with CustomImg, because cloneElement will preserve the ref between the inline image and the lightbox image, and that might cause animation issues
  // const lightboxChild = React.Children.map(children, child => {
  //   return React.cloneElement(child, {
  //     className: `lightboxImg`,
  //   })
  // })

  return (
    <Fragment>
      <button
        className={`${styles.lightboxWrapper}`}
        onClick={() => setLightboxVisible(true)}
        aria-label="View fullscreen"
        style={style}
      >
        {children}
      </button>
      <Lightbox isVisible={lightboxVisible} setIsVisible={setLightboxVisible}>
        {/* {lightboxChild} */}
        <CustomImg
          localFile={localFile}
          alt={alt}
          captionData={captionData}
          className={`lightboxImg`}
        />
        <p className={`h6 lightboxCaption`}>
          {captionData.caption}{' '}
          <span className={`imageCredit`}>{captionData.credit}</span>
        </p>
      </Lightbox>
    </Fragment>
  )
}

export default LightboxImg
