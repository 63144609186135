import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import NewTab from '../icons/NewTab'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import VideoPlayer from '../global/VideoPlayer'
import styles from './OralVideo.module.scss'

gsap.registerPlugin(ScrollTrigger)

const OralVideo = ({
  accent,
  attribution,
  image,
  seeMoreLink,
  seeMoreText,
  quote,
  videoUrl,
}) => {
  const linkText =
    seeMoreText == null || seeMoreText === ''
      ? 'Explore Oral Histories'
      : seeMoreText

  const linkURL = process.env.GATSBY_DEV
    ? seeMoreLink.replace(
        'dutytocountry.org',
        'dutytocountry-v2.whatscookin.com'
      )
    : seeMoreLink

  const textRef = useRef(null)

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        id: `section`,
        trigger: textRef.current,
        start: 'top bottom-=10%',
        end: 'top bottom-=40%',
        toggleActions: 'play none none reverse',
        scrub: 1,
        // markers: true,
      },
    })

    tl.fromTo(
      textRef.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
      }
    )

    // Cleanup the timeline on unmount
    return () => {
      tl.kill()
    }
  }, [textRef])

  return (
    <section className={`relPos `}>
      {/* Background */}
      <div className={`${accent} ${styles.oralHistoryBg}`}></div>
      {/* Grid  */}
      <div className={`${styles.oralHistoryGrid} textColorWhite `}>
        {/* Quote  */}
        <div className={`${styles.quoteContainer} ${accent}`} ref={textRef}>
          <p className={`h5`}>Oral History</p>
          <div className={`mt32 mb32`}>
            <p className={`h4 mb8`}>&#8220;{quote}&#8221;</p>
            <p className={`imageCredit`}>{attribution}</p>
          </div>

          <p className={`h6`}>
            <a
              href={linkURL}
              target="_blank"
              rel="noopener noreferrer"
              className={`textColorWhite`}
            >
              {linkText}
              <i className={`inlineIcon`}>
                <NewTab />
              </i>
            </a>
          </p>
        </div>

        {/* Video  */}
        <div className={`${styles.videoContainer} shadowImage`}>
          <VideoPlayer url={videoUrl} coverImage={image} />
        </div>
      </div>
    </section>
    // <section className={`relPos`}>
    //   <div
    //     className={`pageMargin1Col sectionGrid1fr2fr columnGap0 textColorWhite sectionBottomMarginBig`}
    //   >
    //     <button
    //       className={`${styles.oralVideoPoster}`}
    //       onClick={() => setLightboxVisible(true)}
    //       aria-label={`View Oral History from ${attribution}`}
    //     >
    //       <CustomImg
    //         className={`${styles.oralVideoPosterImg}`}
    //         localFile={image.localFile}
    //         alt={''}
    //       />
    //       <div className={`${styles.playIcon}`}>
    //         <PlayIcon />
    //       </div>
    //     </button>
    //     <div className={`${styles.oralVideoQuote} ${accent}`}>
    //       <p className={`h5 mb32`}>Oral History</p>
    //       <div>
    //         <p className={`h4 mb16`}>&#8220;{quote}&#8221;</p>
    //         <p className={`h6`}>{attribution}</p>
    //       </div>

    //       <LinkWithArrow
    //         className={`${styles.seeMoreLink}`}
    //         linkTo={seeMoreLink}
    //       >
    //         <p>see more</p>
    //       </LinkWithArrow>
    //     </div>
    //   </div>

    //   {/* Lightbox  */}
    //   <Lightbox isVisible={lightboxVisible} setIsVisible={setLightboxVisible}>
    // <div className={`${styles.videoWrapper}`}>
    //   <ReactPlayer
    //     className={`${styles.reactPlayer}`}
    //     width="100%"
    //     height="100%"
    //     url={videoUrl} // correct url
    //     controls
    //     playsinline // Prevent Vimeo from automatically entering fullscreen
    //     playing={lightboxVisible}
    //     onEnded={() => setLightboxVisible(false)}
    //     // volume={volume} // Required for Muted to work
    //     // muted={muted}
    //   />
    //   <svg height="100%" width="100%" fill="none" viewBox="0 0 16 9"></svg>
    // </div>
    //   </Lightbox>
    // </section>
  )
}

OralVideo.propTypes = {
  accent: PropTypes.string,
}

export default OralVideo
