import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/global/Layout'
import SEO from '../components/global/Seo'
import TopNav from '../components/global/TopNav'
import ChapterNavTitle from '../components/chapterNav/ChapterNavTitle'
import SectionHero from '../components/sectionBlocks/SectionHero'
import Animation from '../components/sectionBlocks/Animation'
// import CalloutTextWithImage from '../components/sectionBlocks/CalloutTextWithImage'
import HeroImageText from '../components/sectionBlocks/HeroImageText'
import Illustration from '../components/sectionBlocks/Illustration'
import ImageStack from '../components/sectionBlocks/ImageStack'
import OralVideo from '../components/sectionBlocks/OralVideo'
import PeopleCards from '../components/sectionBlocks/PeopleCards'
import StaggeredImages from '../components/sectionBlocks/StaggeredImages'
import TextWithImage from '../components/sectionBlocks/TextWithImage'
// import TextWithPersonCard from '../components/sectionBlocks/TextWithPersonCard'
import VideoClip from '../components/sectionBlocks/VideoClip'
import NextSection from '../components/sectionBlocks/NextSection'

const SectionTemplate = ({ data }) => {
  const {
    chapter,
    section_title,
    section_desc,
    section_date,
    section_image,
    section_code,
    section_lede,
    section_illustration,
    subsections,
  } = data.strapiSection

  // Get Previous and Next Section
  const { nodes } = data.allStrapiSection
  // console.log(nodes)
  const i = nodes.findIndex(element => section_code === element.section_code)
  let prevSection = null
  let nextSection = null
  if (i !== -1) {
    if (i !== 0) prevSection = nodes[i - 1]
    if (i !== nodes.length - 1) nextSection = nodes[i + 1]
  }

  // Takes the incoming color, and assigns a class to it. That class is passed as a prop. The class updates --accent-color with the appropriate Sass variable.
  const setAccentColor = colorName => {
    if (colorName == null) {
      // if no color, default to RED
      return 'accentRed'
    }
    switch (colorName.color) {
      case 'RED':
        return 'accentRed'
      case 'GREEN':
        return 'accentGreen'
      case 'BLUE':
        return 'accentBlue'
      default:
        break
    }
  }

  return (
    <Layout>
      <SEO
        title={section_title}
        description={section_desc}
        image={section_image}
      />
      <TopNav>
        {/* Chapter Titles that appear in the header  */}
        <div>
          <ChapterNavTitle
            chapterNumber={chapter.subtitle}
            chapterTitle={chapter.title}
          />
        </div>
      </TopNav>
      <SectionHero
        sectionTitle={section_title}
        dateFull={section_date}
        heroImage={section_image}
        sectionCode={section_code}
        sectionLede={section_lede}
        sectionIllustration={section_illustration}
      />
      {subsections.map(
        (
          {
            component_type,
            accent_color,
            attribution,
            animation,
            bg_image,
            header,
            header_style,
            illustration,
            is_transparent,
            paragraph,
            people,
            quote,
            ratio,
            see_more_link,
            see_more_text,
            text_top,
            video_url,
            image,
            images,
          },
          index
        ) => {
          return {
            animation: (
              // <p>animation</p>
              <Animation
                accent={setAccentColor(accent_color)}
                animation={animation}
                key={index}
              />
            ),
            // callout_text_with_image: ( // component archived in strapi
            //   <CalloutTextWithImage
            //     accent={setAccentColor(accent_color)}
            //     paragraph={paragraph}
            //     image={image}
            // key={index}
            //   />
            // ),
            hero_image_text: (
              // <p>hero image text</p>
              <HeroImageText
                accent={setAccentColor(accent_color)}
                heroImage={image}
                header={header}
                headerStyle={header_style}
                textTop={text_top}
                key={index}
              />
            ),
            illustration: (
              // <p>illustration</p>
              <Illustration
                accent={setAccentColor(accent_color)}
                illustration={illustration}
                key={index}
              />
            ),
            image_stack: (
              <ImageStack
                accent={setAccentColor(accent_color)}
                images={images}
                key={index}
              />
            ),
            oral_video: (
              // <p>oral video</p>
              <OralVideo
                accent={setAccentColor(accent_color)}
                attribution={attribution}
                image={image}
                seeMoreLink={see_more_link}
                seeMoreText={see_more_text}
                quote={quote}
                videoUrl={video_url}
                key={index}
              />
            ),
            people_cards: (
              // <p>people cards</p>
              <PeopleCards
                accent={setAccentColor(accent_color)}
                people={people}
                shareButton={true}
                key={index}
              />
            ),
            staggered_images: (
              // <p>staggered images</p>
              <StaggeredImages
                accent={setAccentColor(accent_color)}
                images={images}
                key={index}
              />
            ),
            text_with_image: (
              // <p>text image right</p>
              <TextWithImage
                accent={setAccentColor(accent_color)}
                text={paragraph}
                image={image}
                isTransparent={is_transparent}
                isOdd={index % 2 !== 0}
                bgImage={bg_image}
                key={index}
              />
            ),
            // text_with_person_card: ( //component archived in strapi
            //   // <p>person card</p>
            //   <TextWithPersonCard
            //     accent={setAccentColor(accent_color)}
            //     paragraph={paragraph}
            //     person={person}
            // key={index}
            //   />
            // ),
            video_clip: (
              // <p>text and video</p>
              <VideoClip
                accent={setAccentColor(accent_color)}
                paragraph={paragraph}
                image={image}
                ratio={ratio}
                videoUrl={video_url}
                key={index}
              />
            ),
          }[component_type]
        }
      )}
      {nextSection !== null && (
        <NextSection prevSection={prevSection} nextSection={nextSection} />
      )}
    </Layout>
  )
}

export default SectionTemplate

// sizes: '(max-width: 600px) 300vw, (max-width: 900px) 300vw, (max-width: 1200px) 300vw'
// srcSetBreakpoints: [600, 900, 1200]

// TODO: In section_image, use childImageSharp/fluid instead of publicURL. See graqphiql for image options in fluid
export const query = graphql`
  query SectionTemplate($section_slug: String!) {
    strapiSection(section_slug: { eq: $section_slug }) {
      chapter {
        chapter_number
        title
        subtitle
      }
      section_title
      section_desc
      section_code
      section_lede
      section_image {
        alternativeText
        height
        width
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      section_illustration {
        layers {
          image {
            alternativeText
            url
            localFile {
              publicURL
              childImageSharp {
                fluid(
                  sizes: "(max-width: 359px) 1080px, (max-width: 599px) 1800px, (max-width: 899px) 2700px, (max-width: 1199px) 3600px, 3840px"
                  srcSetBreakpoints: [1080, 1800, 2700, 3600, 3840]
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        zoom_x
        zoom_y
        zoom_width
        bg_scale
      }

      section_date
      subsections {
        component_type
        attribution
        header
        header_style
        is_transparent
        paragraph
        quote
        ratio
        see_more_link
        see_more_text
        text_top
        video_url
        accent_color {
          color
        }
        animation {
          animation_slug
          animation_title
          animation_url
          animation_still {
            alternativeText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        illustration {
          illustration_caption
          illustration_slug
          illustration_title
          illustration_still {
            alternativeText
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        people {
          name
          person_id
          body
          illustration {
            alternativeText
            caption
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        image {
          alternativeText
          caption
          localFile {
            ext
            publicURL
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        bg_image {
          alternativeText
          localFile {
            publicURL
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        images {
          id
          alternativeText
          caption
          localFile {
            ext
            publicURL
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allStrapiSection(sort: { fields: section_code }) {
      nodes {
        section_code
        section_date
        section_title
        section_desc
        section_slug
        chapter {
          chapter_slug
        }
        section_image {
          alternativeText
          caption
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
