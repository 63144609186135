import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import ShareLinkButton from '../global/ShareLinkButton'
import ArrowIcon from '../icons/ArrowIcon'
import NextSectionCard from './NextSectionCard'

import styles from './NextSection.module.scss'

const NextSection = ({ prevSection, nextSection }) => {
  let prevLink = ''
  if (prevSection !== null) {
    prevLink =
      prevSection.chapter.chapter_slug + '/' + prevSection.section_slug + '/'
  }
  const {
    chapter,
    section_slug,
    section_code,
    section_date,
    section_title,
    section_desc,
    section_image,
  } = nextSection

  // const addToTextPositions = newTextPos => {
  //   console.log(newTextPos)
  // }

  return (
    <div className={`pt64`}>
      <div className={`flex justifyContentCenter pt64 pb64`}>
        <ShareLinkButton className={`mr16`} linkPage={''} currentPage={true} />
        <Link to={'#'}>
          <button className={`${styles.sectionEndArrow}`}>
            <p className={`h5`}>Jump to Top</p>
            <ArrowIcon />
          </button>
        </Link>
      </div>

      <div className={`${styles.nextSection}`}>
        <div className={`pageMargin1Col relPos pt16`}>
          <p className={`pb16 h4 textCenter`}>Next Section</p>
        </div>

        <div className={`relPos ${styles.sectionCard}`}>
          <NextSectionCard
            key={section_slug}
            sectionDate={section_date}
            sectionTitle={section_title}
            sectionDesc={section_desc}
            sectionImage={section_image}
            chapterSlug={chapter.chapter_slug}
            sectionSlug={section_slug}
            sectionCode={section_code}
            // addToTextPositions={addToTextPositions}
            // setActiveDot={addToTextPositions}
            index={0}
          />
        </div>

        <div className={`${styles.footerLink}`}>
          <div className={`pageMargin1Col flexTabletUp justifyContentBetween`}>
            <div className={`pb16`} style={{ minWidth: '20%' }}>
              {prevSection !== null && (
                <Link to={'/' + prevLink} className={`${styles.linkWithArrow}`}>
                  <div className={`${styles.linkArrow}`}>
                    <ArrowIcon />
                  </div>
                  <p className={`h5`}>Previous Section</p>
                </Link>
              )}
            </div>

            <Link to={'/'}>
              <p className={`h5 pb16`}>All Chapters</p>
            </Link>

            <Link
              to={`/${chapter.chapter_slug}/${section_slug}/`}
              className={`${styles.linkWithArrow}`}
            >
              <p className={`h5 pb16`}>Next Section</p>
              <div className={`${styles.linkArrow} ${styles.next}`}>
                <ArrowIcon />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

NextSection.propTypes = {
  prevSection: PropTypes.object,
  nextSection: PropTypes.object.isRequired,
}

export default NextSection
