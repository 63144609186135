import React, { useState } from 'react'
import ReactPlayer from 'react-player/vimeo'
import CustomImg from '../global/CustomImg'
import PlayIcon from '../icons/PlayIcon'
import styles from './VideoPlayer.module.scss'

const VideoPlayer = ({ url, coverImage }) => {
  const [videoPlaying, setVideoPlaying] = useState(false)

  // Hide the overlay if the video is playing
  let overlayOpacity = videoPlaying ? '0' : ''
  let overlayPointerEvents = videoPlaying ? 'none' : ''

  return (
    <div className={`${styles.videoWrapper}`}>
      <div
        className={`${styles.videoOverlay}`}
        style={{
          opacity: overlayOpacity,
          pointerEvents: overlayPointerEvents,
        }}
      >
        <div className={`${styles.coverImageShade}`}>
          <CustomImg
            className={`${styles.coverImage}`}
            localFile={coverImage.localFile}
            alt={''}
          />
        </div>
        <button
          className={`${styles.playBtn}`}
          type="button"
          onClick={() => setVideoPlaying(true)}
          aria-label="Play Video"
        >
          <PlayIcon />
        </button>
      </div>
      <ReactPlayer
        className={`${styles.reactPlayer}`}
        width="100%"
        height="100%"
        url={url}
        controls
        playsinline // Prevent Vimeo from automatically entering fullscreen
        playing={videoPlaying}
        onEnded={() => setVideoPlaying(false)}
        // config={{
        //   vimeo: {
        //     color: accent, //TODO: set up color for funsies!
        //   },
        // }}
        // volume={volume} // Required for Muted to work
        // muted={muted}
      />
    </div>
  )
}

export default VideoPlayer
