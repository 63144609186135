import PropTypes from 'prop-types'
import React from 'react'

// Illustration Components
import MissingIllustration from '../illustrations/MissingIllustration'

const Illustration = ({ accent, illustration }) => {
  // const {
  //   illustration_caption,
  //   illustration_slug,
  //   illustration_title,
  //   illustration_still,
  // } = illustration

  if (illustration === null || typeof illustration === 'undefined') {
    return (
      <section className={`sectionBottomMarginBig`}>
        <p className={`pb40 textColorTamari pageMargin2Col`}>
          ERROR: No animation component attached in strapi
        </p>
      </section>
    )
  } else {
    return (
      <section className={`relPos`}>
        {/* <div className={`sectionBottomMarginBig pageMarginRatio`}> */}
        <div>
          <figure>
            <MissingIllustration accent={accent} illustration={illustration} />
            {
              // Default:
              // <div className={`pageMarginRatio`}>
              //   <CustomImg
              //     localFile={illustration_still.localFile}
              //     alt={illustration_still.alternativeText}
              //   />
              //   <figcaption className={`captionParent mt16`}>
              //     <p className={`h5 ${accent} accentText`}>
              //       {illustration_title}
              //     </p>
              //     <p className={`h6`}>{illustration_caption}</p>
              //   </figcaption>
              // </div>
            }
          </figure>

          {/* <div className={`${styles.blurredImageWrapper}`}>
            <div className={`${styles.blurredImage}`}>
              <CustomImg
                localFile={illustration_still.localFile}
                alt={illustration_still.alternativeText}
              />
            </div>
          </div>
          <p>{`Warning: ${illustration_slug} did not match a dynamic illustration component. Check your illustration_slug.`}</p> */}
        </div>
      </section>
      // <section className={`relPos`}>
      //   <div className={`sectionBottomMarginBig pageMarginRatio`}>
      //     <figure>
      //       {{
      //         'illus-090-i-shall-return': <IShallReturn />,
      //       }[illustration_slug] || (
      //         // Default:
      //         <Fragment>
      //           <CustomImg
      //             localFile={illustration_still.localFile}
      //             alt={illustration_still.alternativeText}
      //           />
      //           <p>{`Warning: ${illustration_slug} did not match a dynamic illustration component. Check your illustration_slug.`}</p>
      //         </Fragment>
      //       )}
      //       <figcaption className={`captionParent mt16`}>
      //         <p className={`h5 ${accent} accentText`}>{illustration_title}</p>
      //         <p className={`h6`}>{illustration_caption}</p>
      //       </figcaption>
      //     </figure>
      //   </div>
      // </section>
    )
  }
}

Illustration.propTypes = {
  accent: PropTypes.string,
}

export default Illustration
