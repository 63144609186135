import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import GetImgCaptionFormat from '../../utils/GetImgCaptionFormat'
import CustomImg from '../global/CustomImg'
import Lightbox from '../global/Lightbox'
import styles from './ImageStack.module.scss'
gsap.registerPlugin(ScrollTrigger)

// Setup refs for GSAP animations
const ImageStack = ({ images, accent }) => {
  // const stickyRef = useRef(null)

  // const photoGroupsRef = useRef({})
  // photoGroupsRef.current = []
  // const addToPhotoGroupsRef = group => {
  //   if (group && !photoGroupsRef.current.includes(group)) {
  //     photoGroupsRef.current.push(group)
  //   }
  // }

  // const photosRef = useRef({})
  // photosRef.current = []

  // const addToPhotosRef = photo => {
  //   if (photo && !photosRef.current.includes(photo)) {
  //     photosRef.current.push(photo)
  //   }
  // }

  // const captionsRef = useRef({})
  // captionsRef.current = []

  // const addToCaptionsRef = caption => {
  //   if (caption && !captionsRef.current.includes(caption)) {
  //     captionsRef.current.push(caption)
  //   }
  // }

  // const [inTl, setInTl] = useState(null)
  // const [tl, setTl] = useState(null)
  // const [masterTl, setMasterTl] = useState(gsap.timeline())

  // useEffect(() => {
  //   setInTl(
  //     gsap.timeline({
  //       scrollTrigger: {
  //         id: `in-container`,
  //         trigger: stickyRef.current,
  //         start: 'top bottom-=20%',
  //         // start: 'top bottom-=20%',
  //         end: '100px center',
  //         toggleActions: 'play none none reverse',
  //         scrub: 1,
  //       },
  //     })
  //   )
  //   setTl(
  //     gsap.timeline({
  //       scrollTrigger: {
  //         id: `sticky-container`,
  //         trigger: stickyRef.current,
  //         start: 'top top+=20%',
  //         // start: 'center center',
  //         // start: 'top bottom-=20%',
  //         end: 'bottom top+=30%',
  //         // end: '1800px',
  //         toggleActions: 'play none none reverse',
  //         // markers: true,
  //         pin: stickyRef.current,
  //         anticipatePin: 1,
  //         pinSpacing: false,
  //         scrub: 1,
  //       },
  //     })
  //   )
  // }, [stickyRef])

  // // Animations

  // // Fade in the photos
  // // There's code here to move and rotate the photos in too, but it was making me a little motion sick...
  // useEffect(() => {
  //   inTl &&
  //     inTl.to(
  //       photosRef.current,
  //       {
  //         // xPercent: 0,
  //         opacity: 1,
  //         // stagger: -0.3,
  //         ease: 'power1.inOut',
  //       },
  //       'photo-in'
  //     )
  //   // .to(
  //   //   photosRef.current[2],
  //   //   {
  //   //     // xPercent: 0,
  //   //     x: '-1.5rem',
  //   //     y: '-1rem',
  //   //     rotation: -4,
  //   //     duration: 1,
  //   //     ease: 'power1.inOut',
  //   //   },
  //   //   'photo-in'
  //   // )
  //   // .to(photosRef.current[1], {
  //   //   // xPercent: 0,
  //   //   x: 0,
  //   //   y: 0,
  //   //   rotation: -2,
  //   //   duration: 1,
  //   //   ease: 'power1.inOut',
  //   // })
  //   // .to(photosRef.current[0], {
  //   //   // xPercent: 0,
  //   //   x: '1.5rem',
  //   //   y: '1rem',
  //   //   rotation: 0,
  //   //   duration: 1,
  //   //   ease: 'power1.inOut',
  //   // })
  //   masterTl.add(inTl, 0)
  // }, [inTl])

  // useEffect(() => {
  //   // TODO: Create new timelines for first and last photos? Ex., first caption would start visible. Last photo and caption wouldn't animate out.

  //   tl &&
  //     // Loop through the photoGroupRefs and create a timeline for each one
  //     photoGroupsRef.current.map((group, index) => {
  //       tl.to(captionsRef.current[index], {
  //         opacity: 1,
  //         duration: 1,
  //       })
  //         .to(
  //           photosRef.current[index],
  //           {
  //             rotation: 0,
  //             duration: 1,
  //             ease: 'power1.in',
  //           },
  //           '-=3'
  //         ) // Straighten the photo while the one above it is moving
  //         .add('move-photo', '+=1') // delay moving the photo, so that it doesn't move as soon as the the caption is visible
  //         .to(
  //           photosRef.current[index],
  //           {
  //             xPercent: -200,
  //             rotation: -25,
  //             duration: 2,
  //             ease: 'power1.in',
  //           },
  //           'move-photo'
  //         )
  //         .to(
  //           captionsRef.current[index],
  //           {
  //             opacity: 0,
  //             duration: 1,
  //           },
  //           'move-photo' // caption starts to fade when the photo starts to move
  //         )
  //         .to(
  //           photoGroupsRef.current[index],
  //           {
  //             // zIndex: index * -1 - 5
  //             pointerEvents: 'none',
  //           },
  //           '-=1.5'
  //         )
  //       // Add the current timeline to the master
  //       masterTl.add(tl, '-=2')
  //       // console.log('another update')
  //     })
  // }, [tl])

  // // Clean up the timeline when the component is unmounted
  // // FIXME: Hot reloads still mess up the timeline, so I guess this isn't working
  // useEffect(() => {
  //   return () => {
  //     tl && tl.kill()
  //     masterTl && masterTl.kill()
  //   }
  //   // eslint-disable-next-line
  // }, [])

  const stickyRef = useRef(null)

  const photoGroupsRef = useRef([])

  const addToPhotoGroupsRef = group => {
    if (group && !photoGroupsRef.current.includes(group)) {
      photoGroupsRef.current.push(group)
    }
  }

  const photosRef = useRef([])

  const addToPhotosRef = photo => {
    if (photo && !photosRef.current.includes(photo)) {
      photosRef.current.push(photo)
    }
  }

  const captionsRef = useRef([])

  const addToCaptionsRef = caption => {
    if (caption && !captionsRef.current.includes(caption)) {
      captionsRef.current.push(caption)
    }
  }

  useEffect(() => {
    const inTl = gsap.timeline({
      scrollTrigger: {
        id: `in-container`,
        trigger: stickyRef.current,
        start: 'top bottom-=20%',
        end: '100px center',
        toggleActions: 'play none none reverse',
        scrub: 1,
      },
    })

    const tl = gsap.timeline({
      scrollTrigger: {
        id: `sticky-container`,
        trigger: stickyRef.current,
        start: 'top top+=25%',
        end: 'bottom top+=30%',
        toggleActions: 'play none none reverse',
        pin: stickyRef.current,
        anticipatePin: 1,
        pinSpacing: false,
        scrub: 1,
        // markers: true,
      },
    })

    // Fade in the photos
    inTl.to(
      photosRef.current,
      {
        opacity: 1,
        ease: 'power1.inOut',
      },
      'photo-in'
    )

    // Loop through the photoGroupRefs and create a timeline for each one
    photoGroupsRef.current.forEach((group, index) => {
      tl.to(captionsRef.current[index], {
        opacity: 1,
        duration: 1,
      })
        .to(
          photosRef.current[index],
          {
            rotation: 0,
            duration: 1,
            ease: 'power1.in',
          },
          '-=3'
        ) // Straighten the photo while the one above it is moving
        .add('move-photo', '+=1') // delay moving the photo, so that it doesn't move as soon as the the caption is visible
        .to(
          photosRef.current[index],
          {
            xPercent: -200,
            rotation: -25,
            duration: 2,
            ease: 'power1.in',
          },
          'move-photo'
        )
        .to(
          captionsRef.current[index],
          {
            opacity: 0,
            duration: 1,
          },
          'move-photo' // caption starts to fade when the photo starts to move
        )
        .to(
          photoGroupsRef.current[index],
          {
            pointerEvents: 'none',
          },
          '-=1.5'
        )
    })
    // Cleanup the timeline on unmount
    return () => {
      inTl.kill()
      tl.kill()
    }
  }, [stickyRef, photosRef, captionsRef, photoGroupsRef])

  // Lightbox state
  const [lightboxVisible, setLightboxVisible] = useState(null)

  return (
    <section className={`relPos`}>
      <div className={`${styles.photoStack}`}>
        {/* TODO: add breakpoints for the Sticky Container height, and maybe try to center the child elements? */}
        {/* Sticky Container */}
        <div
          className={`relPos`}
          style={{
            height: `calc(${images.length} * 80vh )`,
          }} // height value has affects animation speed
          ref={stickyRef}
        >
          {images.map(({ localFile, alternativeText, caption, id }, index) => {
            let captionCreditData = GetImgCaptionFormat(caption)
            const { aspectRatio } = localFile.childImageSharp.fluid

            return (
              <Fragment key={index}>
                <figure
                  key={id}
                  className={`${styles.photoStackGroup}`}
                  style={{ zIndex: `${index * -1}` }}
                  ref={addToPhotoGroupsRef}
                >
                  {/* Photo  */}

                  <div
                    className={`${styles.photoWrapper} ${
                      aspectRatio < 1 && styles.portraitWrapper
                    }`}
                    tabIndex="0"
                    role="button"
                    onClick={() => {
                      setLightboxVisible(index)
                    }}
                    // FIXME this works, but you can't focus on the X to close the lightbox
                    // so might want to remove this everywhere an image opens the lightbox
                    onKeyDown={e => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        setLightboxVisible(index)
                      }
                    }}
                    ref={addToPhotosRef}
                    aria-label="View fullscreen"
                  >
                    <CustomImg
                      localFile={localFile}
                      alt={alternativeText}
                      className={`${styles.photoStackPhoto} photo`}
                      style={{ overflow: 'visible' }}
                    />
                  </div>
                  {/* Can't use the FigCaption component here because of the ref */}
                  <figcaption
                    className={`${styles.photoStackCaption} mt16 mb16 pl8 accentBar ${accent}`}
                    ref={addToCaptionsRef}
                  >
                    {captionCreditData.caption}{' '}
                    <span className={`imageCredit`}>
                      {captionCreditData.credit}
                    </span>
                  </figcaption>
                </figure>
              </Fragment>
            )
          })}
        </div>
        {images.map(({ localFile, alternativeText, caption, id }, index) => {
          let captionCreditData = GetImgCaptionFormat(caption)
          return (
            <Lightbox
              isVisible={lightboxVisible === index}
              setIsVisible={setLightboxVisible}
              key={index}
            >
              <CustomImg
                localFile={localFile}
                alt={alternativeText}
                className={`lightboxImg`}
              />
              <p className={`h6 lightboxCaption`}>
                {captionCreditData.caption}{' '}
                <span className={`imageCredit lightboxCaption`}>
                  {captionCreditData.credit}
                </span>
              </p>
            </Lightbox>
          )
        })}
      </div>
    </section>
  )
}

ImageStack.propTypes = {
  images: PropTypes.array,
}

export default ImageStack
