import React from 'react'
import styles from './ChapterNavTitle.module.scss'

const ChapterNavTitle = ({ chapterNumber, chapterTitle }) => {
  return (
    <p className={`h5 ${styles.chapterTitle}`}>
      <span className={`textColorFuzzy ${styles.chapterNumber}`}>
        {chapterNumber}:{' '}
      </span>
      {chapterTitle}
    </p>
  )
}

export default ChapterNavTitle
