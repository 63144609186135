import React from 'react'

const NewTab = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	    viewBox="0 0 512 512" style={{enableBackground: 'new 0 0 512 512'}}
    >
      <g id="XMLID_1_">
        <path id="XMLID_5_" d="M511.5,0.9v255.5h-34.4V63.2L159.4,381l-24.2-24.2L457.6,34.4H256V0h255.5V0.9z M374.9,477.6H34.8V137.5
          h223.9v-34.4H0.5V512h408.9V249h-34.4V477.6z"/>
      </g>
    </svg>
  )
}

export default NewTab
