import React from 'react'
import PropTypes from 'prop-types'
import styles from './FigCaption.module.scss'

const FigCaption = ({ accent, caption, style }) => {
  return (
    <figcaption
      className={`accentBar ${accent} ${styles.captionContainer}`}
      style={style}
    >
      {caption.caption} <span className={`imageCredit`}>{caption.credit}</span>
    </figcaption>
  )
}

FigCaption.propTypes = {
  accent: PropTypes.string,
  caption: PropTypes.object.isRequired,
  style: PropTypes.object,
}

export default FigCaption
