import React, { Fragment } from 'react'
import CustomImg from '../global/CustomImg'

const MissingIllustration = ({ accent, illustration }) => {
  const {
    illustration_caption,
    // illustration_slug,
    illustration_title,
    illustration_still,
  } = illustration
  return (
    <Fragment>
      <div className={`relPos`}>
        <div className={`pageMarginRatio`}>
          <CustomImg
            localFile={illustration_still.localFile}
            alt={illustration_still.alternativeText}
          />
        </div>
        <div className={`blurredImageWrapper`}>
          <div className={`blurredImage`}>
            <CustomImg
              localFile={illustration_still.localFile}
              alt={illustration_still.alternativeText}
            />
          </div>
        </div>
      </div>
      <figcaption className={`fullWidthCaptionContainer`}>
        <p className={`h5 ${accent} accentText`}>{illustration_title}</p>

        <p className={`${accent} figcaption accentBar pl8 mt8`}>
          {illustration_caption}
        </p>
      </figcaption>
      {/* <p>{`Warning: ${illustration_slug} did not match a dynamic illustration component. Check your illustration_slug.`}</p> */}
    </Fragment>
  )
}

export default MissingIllustration
