import React from 'react'
//import ReactPlayer from 'react-player/vimeo'
import LinkWithArrow from '../buttons/LinkWithArrow'
import CustomImg from '../global/CustomImg'
import ShareLinkButton from '../global/ShareLinkButton'
import VideoPlayer from '../global/VideoPlayer'
import styles from './Animation.module.scss'

const Animation = ({ accent, animation }) => {
  // const [videoPlaying, setVideoPlaying] = useState(false)

  // // Hide the overlay if the video is playing
  // let overlayOpacity = videoPlaying ? '0' : ''
  // let overlayPointerEvents = videoPlaying ? 'none' : ''

  if (animation === null || typeof animation === 'undefined') {
    return (
      <div className={`sectionBottomMarginBig pageMarginRatio`}>
        <p className={`pb40 textColorTamari pageMargin2Col`}>
          ERROR: No animation component attached in strapi
        </p>
      </div>
    )
  } else {
    const {
      animation_title,
      animation_url,
      animation_still,
      animation_slug,
    } = animation
    return (
      <section className={`relPos`}>
        {/* <div className={`pageMarginRatio`}> */}
        {/* <VideoPlayer url={animation_url} coverImage={animation_still} /> */}

        <div className={`${styles.videoContainer}`}>
          <div className={`pageMarginRatio`}>
            <VideoPlayer url={animation_url} coverImage={animation_still} />
          </div>
          <div className={`blurredImageWrapper`}>
            <div className={`blurredImage`}>
              <CustomImg
                localFile={animation_still.localFile}
                alt={animation_still.alternativeText}
              />
            </div>
          </div>
        </div>
        <div className={`fullWidthCaptionContainer flex justifyContentBetween`}>
          <p className={`h5 ${accent} accentText`}>{animation_title}</p>
          <LinkWithArrow
            linkText={`More Animations`}
            linkTo={'/animation-gallery/'}
          ></LinkWithArrow>
          <ShareLinkButton
            className={`${styles.shareBtn}`}
            linkPage={`animation-gallery/${animation_slug}/`}
          />
        </div>
        {/* </div> */}
      </section>
    )
  }
}

export default Animation
