import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import backgroundImg from '../backgrounds/hafltone4.png'
import CustomImg from '../global/CustomImg'
import IllusImg from '../global/IllusImg'
import styles from './SectionHero.module.scss'
gsap.registerPlugin(ScrollTrigger)

// // Debouncing resize events
// function debounce(fn, ms) {
//   let timer
//   return _ => {
//     clearTimeout(timer)
//     timer = setTimeout(_ => {
//       timer = null
//       fn.apply(this, arguments)
//     }, ms)
//   }
// }

const SectionHero = ({
  sectionTitle,
  dateFull,
  heroImage,
  sectionCode,
  sectionLede,
  sectionIllustration,
}) => {
  // console.log('SectionHero render')
  // console.log(heroImage.localFile)

  const { layers } = sectionIllustration !== null && sectionIllustration

  // Set default zoom values in case they're not provided from the CMS
  const zoom_x = (sectionIllustration && sectionIllustration.zoom_x) || 0
  const zoom_y = (sectionIllustration && sectionIllustration.zoom_y) || 0
  const zoom_width =
    (sectionIllustration && sectionIllustration.zoom_width) || 1280

  // Get X, Y and Width zooming values from the CMS
  // X and Y come in as top left coordinates, but we need them to be center center, and they need to be converted to percentage values.
  const xPercentVal = ((zoom_x + zoom_width / 2) / 3840) * 100
  const yPercentVal = ((zoom_y + (zoom_width * 0.5625) / 2) / 2160) * 100
  const zoomPercent = 3840 / zoom_width

  // Count the layers as they load and update the loading state
  const layersLoaded = useRef(0)
  const [loadingState, setLoadingState] = useState(true)

  const imageLoaded = () => {
    layersLoaded.current += 1
    if (
      typeof layers !== 'undefined' &&
      layersLoaded.current >= layers.length
    ) {
      setLoadingState(false)
    }
  }

  // Disable scrolling while the layers load.
  // Note: scrollbars will still be visible
  useEffect(() => {
    const noScroll = () => {
      window.scrollTo(0, 0)
      // document.body.style.overflowY = 'hidden'
    }
    const yesScroll = () => {
      window.removeEventListener('scroll', noScroll)
      // document.body.style.overflowY = 'unset'
    }

    if (loadingState === true) {
      window.addEventListener('scroll', noScroll)
    } else {
      setTimeout(yesScroll, 1500) // Runs at the end of the fade animation
    }
    return () => {
      window.removeEventListener('scroll', noScroll)
    }
  }, [loadingState])

  // If the user has tried to scroll during loading, move them back to the top
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'auto' })
    }
    if (loadingState === false) {
      window.setTimeout(scrollToTop, 1500) // Runs at the end of the fade animation
    }
  }, [loadingState])

  // Illustration Stuff starts here
  // Add a namespace in case we end up with multiple illustrations on a page that share ids
  const prefix = `illus${sectionCode}`

  // const SectionHero = props => {
  const loadingRef = useRef(null)
  const spinnerRef = useRef(null)
  const sectionHeroRef = useRef(null)
  const stageRef = useRef(null)
  const animElements = useRef({})

  useEffect(() => {
    const outScrollTl = gsap.timeline({
      scrollTrigger: {
        id: `${prefix}-stage`,
        trigger: sectionHeroRef.current,
        start: 'top top+=40px',
        // start: 'top top',
        end: '1800px',
        pin: sectionHeroRef.current,
        anticipatePin: 1,
        scrub: 1, // scrub with momentum/smoothing
        // markers: true,
        // pinReparent: true,
      },
    })

    if (typeof layers !== 'undefined' && layers.length) {
      // console.log(outScrollTl)
      animElements.current = stageRef.current && {
        bgOverlay: stageRef.current.querySelector(`#${prefix}-bg-overlay`),
        bgImage: stageRef.current.querySelector(`.${prefix}-bg-image`),
        camera: stageRef.current.querySelector(`#${prefix}-camera`),
        layer0: stageRef.current.querySelector(`#${prefix}-layer0`),
        layer1: stageRef.current.querySelector(`#${prefix}-layer1`),
        layer2: stageRef.current.querySelector(`#${prefix}-layer2`),
        layer3: stageRef.current.querySelector(`#${prefix}-layer3`),
        colorOverlay: stageRef.current.querySelector(
          `#${prefix}-color-overlay`
        ),
        title: stageRef.current.querySelector(`#${prefix}-title`),
        lede: stageRef.current.querySelector(`#${prefix}-lede`),
      }
      // List the names of all the elements, as defined above
      const {
        bgOverlay,
        bgImage,
        camera,
        layer0,
        layer1,
        layer2,
        layer3,
        colorOverlay,
        title,
        lede,
      } = animElements.current !== null && animElements.current

      // Mobile and portrait animation
      ScrollTrigger.matchMedia({
        '(max-aspect-ratio: 1/1)': function () {
          // Set initial positions
          gsap.set(camera, { filter: 'saturate(0)' })
          // Foreground
          gsap.set(layer0, {
            scale: zoomPercent,
            xPercent: 50 - xPercentVal,
            yPercent: 50 - yPercentVal,
            transformOrigin: `${xPercentVal}% ${yPercentVal}%`,
          })
          // Midground 1
          gsap.set(layer1, {
            scale: zoomPercent - 0.3,
            xPercent: (50 - xPercentVal) / 1.3,
            yPercent: (50 - yPercentVal) / 1.3,
            transformOrigin: `${xPercentVal}% ${yPercentVal}%`,
          })
          // Midground 2
          gsap.set(layer2, {
            scale: zoomPercent - 0.6,
            xPercent: (50 - xPercentVal) / 1.6,
            yPercent: (50 - yPercentVal) / 1.6,
            transformOrigin: `${xPercentVal}% ${yPercentVal}%`,
          })
          // Background
          gsap.set(layer3, {
            scale: zoomPercent - 0.9,
            xPercent: (50 - xPercentVal) / 1.9,
            yPercent: (50 - yPercentVal) / 1.9,
            transformOrigin: `${xPercentVal}% ${yPercentVal}%`,
          })
          gsap.set(lede, { opacity: 0, yPercent: 10 })

          // outScrollTl &&
          outScrollTl
            .clear()
            .to(title, { opacity: 0, yPercent: -10, duration: 1 })
            .to(lede, { opacity: 1, yPercent: 0, duration: 1 })
            // .to(lede, { opacity: 0, yPercent: -20, duration: 1 }, '+=1')
            .to(colorOverlay, { opacity: 0, duration: 1 }, 'overlay')
            .to(camera, { filter: 'saturate(1)', duration: 1 }, 'overlay')
            .to(
              [layer0, layer1, layer2, layer3],
              {
                scale: 1,
                xPercent: 0,
                yPercent: 0,
                duration: 3,
              },
              'zoom'
            )
            .to(camera, { opacity: 0.15, duration: 1 }, '+=2')
        },
      })

      // Landscape and Desktop animation
      ScrollTrigger.matchMedia({
        '(min-aspect-ratio: 1/1)': function () {
          // Set initial positions
          gsap.set(camera, { filter: 'saturate(0)' })
          // Foreground
          gsap.set(layer0, {
            scale: zoomPercent,
            xPercent: 50 - xPercentVal,
            yPercent: 50 - yPercentVal,
            transformOrigin: `${xPercentVal}% ${yPercentVal}%`,
          })
          // Midground 1
          gsap.set(layer1, {
            scale: zoomPercent - 0.3,
            xPercent: (50 - xPercentVal) / 1.3,
            yPercent: (50 - yPercentVal) / 1.3,
            transformOrigin: `${xPercentVal}% ${yPercentVal}%`,
          })
          // Midground 2
          gsap.set(layer2, {
            scale: zoomPercent - 0.6,
            xPercent: (50 - xPercentVal) / 1.6,
            yPercent: (50 - yPercentVal) / 1.6,
            transformOrigin: `${xPercentVal}% ${yPercentVal}%`,
          })
          // Background
          gsap.set(layer3, {
            scale: zoomPercent - 0.9,
            xPercent: (50 - xPercentVal) / 1.9,
            yPercent: (50 - yPercentVal) / 1.9,
            transformOrigin: `${xPercentVal}% ${yPercentVal}%`,
          })
          gsap.set(lede, { opacity: 0, yPercent: 20 })

          // Add tweens to the timeline

          // outScrollTl &&
          outScrollTl
            .clear()
            .to(title, { opacity: 0, yPercent: -20, duration: 1 })
            .to(lede, { opacity: 1, yPercent: 0, duration: 1 })
            .to(lede, { opacity: 0, yPercent: -20, duration: 1 }, '+=1')
            .to(
              [bgOverlay, colorOverlay],
              { opacity: 0, duration: 1 },
              'overlay'
            )
            .to(
              bgImage,
              { filter: 'blur(15px) saturate(1)', duration: 1 },
              'overlay'
            )
            .to(camera, { filter: 'saturate(1)', duration: 1 }, 'overlay')
            .to(
              [layer0, layer1, layer2, layer3],
              {
                scale: 1,
                xPercent: 0,
                yPercent: 0,
                duration: 3,
              },
              'zoom'
            )
            .to(
              [bgImage, camera],
              { opacity: 0, duration: 1, stagger: 0.25 },
              '+=2'
            )
        },
      })
    }

    // Try to kill the timelines when the component unmounts. Hopefully this resolves hot-loading issues, without causing new issues.
    return () => {
      outScrollTl.kill()
    }
    // eslint-disable-next-line
  }, [stageRef, layers])

  // const spinnerTl = useRef(gsap.timeline({}))

  useEffect(() => {
    const spinnerTl = gsap.timeline()
    // Loop the spinner infinitely while loading
    loadingState === true &&
      spinnerTl.to(spinnerRef.current, {
        rotate: '360deg',
        duration: 3,
        ease: 'linear',
        repeat: -1,
      })
    // After loading rotate the spinner one more time
    loadingState === false &&
      spinnerTl.clear().to(spinnerRef.current, {
        rotate: '360deg',
        duration: 3,
        ease: 'linear',
        repeat: 0,
      })
    return () => {
      spinnerTl.kill()
    }
  }, [loadingState])

  // Hide the loading state with an animation
  useEffect(() => {
    loadingState === false &&
      gsap.to(loadingRef.current, {
        opacity: 0,
        duration: 1,
        delay: 0.5,
        ease: 'power1.out',
      })
    // loadingState === false && console.log('loading finished')
  }, [loadingRef, loadingState])

  // // Refresh ScrollTrigger when the window is resized
  // useEffect(() => {
  //   const debouncedHandleResize = debounce(function handleResize() {
  //     ScrollTrigger.refresh()
  //   }, 1000)

  //   window.addEventListener('resize', debouncedHandleResize)

  //   return _ => {
  //     window.removeEventListener('resize', debouncedHandleResize)
  //   }
  // })

  return (
    <section
      className={`${styles.sectionHeroContainer}`}
      style={{
        background: `center / 115% repeat-y url(${backgroundImg}), linear-gradient(#151C1E, #111718)`,
      }}
    >
      <div className={`${styles.sectionHero}`} ref={sectionHeroRef}>
        {typeof layers !== 'undefined' && layers.length ? (
          <Fragment>
            <div className={`${styles.loading}`} ref={loadingRef}>
              <div
                className={`${styles.loadingSpinner}`}
                ref={spinnerRef}
              ></div>
              <div className={`${styles.loadingImageWrapper}`}>
                <CustomImg
                  localFile={heroImage.localFile}
                  alt={''}
                  loading={'eager'}
                />
              </div>
            </div>
            <div
              id={`${prefix}-stage`}
              className={`${styles.stage}`}
              ref={stageRef}
              style={{ opacity: loadingState ? '0' : '1' }} // Hide the illustration layers until they've loaded
            >
              <div className={`${styles.backgroundImageContainer}`}>
                <div
                  id={`${prefix}-bg-overlay`}
                  className={`${styles.backgroundImageOverlay}`}
                ></div>
                {/* Use the bottom layer as a blurred background image  */}
                <CustomImg
                  localFile={
                    layers[3]
                      ? layers[3].image.localFile
                      : layers[0].image.localFile
                  }
                  alt={''}
                  loading={'eager'}
                  className={`${styles.backgroundImage} ${prefix}-bg-image`}
                />
              </div>
              <div className={`${styles.newSixteenNineBox}`}>
                <div id={`${prefix}-camera`} className={`${styles.camera}`}>
                  {/* Illustration layers  */}
                  <div id={`${prefix}-layer3`} className={`${styles.layer3}`}>
                    {layers[3] && (
                      <IllusImg
                        localFile={layers[3].image.localFile}
                        alt=""
                        imageLoaded={imageLoaded}
                        loading={'eager'}
                      />
                    )}
                  </div>
                  <div id={`${prefix}-layer2`} className={`${styles.layer2}`}>
                    {layers[2] && (
                      <IllusImg
                        localFile={layers[2].image.localFile}
                        alt=""
                        imageLoaded={imageLoaded}
                        loading={'eager'}
                      />
                    )}
                  </div>
                  <div id={`${prefix}-layer1`} className={`${styles.layer1}`}>
                    {layers[1] && (
                      <IllusImg
                        localFile={layers[1].image.localFile}
                        alt=""
                        imageLoaded={imageLoaded}
                        loading={'eager'}
                      />
                    )}
                  </div>
                  <div id={`${prefix}-layer0`} className={`${styles.layer0}`}>
                    <IllusImg
                      localFile={layers[0].image.localFile}
                      alt=""
                      imageLoaded={imageLoaded}
                      loading={'eager'}
                    />
                  </div>
                </div>
                <div
                  id={`${prefix}-color-overlay`}
                  className={`${styles.colorOverlay}`}
                ></div>
              </div>
              <div
                id={`${prefix}-text-container`}
                className={`${styles.heroTextContainer}`}
              >
                <div id={`${prefix}-title`} className={`${styles.title}`}>
                  <p className={`h4 ${styles.date}`}>{dateFull}</p>
                  <h1 className={`mb16`}>
                    <span className={``}>{sectionTitle}</span>
                  </h1>
                </div>
                <div id={`${prefix}-lede`} className={`h3 ${styles.lede}`}>
                  <ReactMarkdown
                    source={`## ${sectionLede}`}
                    allowedTypes={['heading', 'text', 'strong']}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
          // TODO: Check a section that doesn't have layers
          // If there aren't animation layers, load the static image and title
          <div></div>
        )}
      </div>
    </section>
  )
}

SectionHero.propTypes = {
  sectionTitle: PropTypes.string,
  dateFull: PropTypes.string,
  heroImage: PropTypes.object,
  sectionCode: PropTypes.string,
  sectionLede: PropTypes.string,
  sectionIllustration: PropTypes.object,
}
export default SectionHero
