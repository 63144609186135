import React from 'react'

const PlayIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Play"
    >
      <path
        d="M19.1109601 12.4257425L6.76218941 20.0306131c-.23513111.1448032-.54312874.0715779-.68793195-.1635532C6.02570731 19.7882243 6 19.6974565 6 19.6048705V4.39512949c0-.27614238.22385763-.5.5-.5.09258602 0 .18335379.02570731.26218941.07425746L19.1109601 11.5742575c.2351312.1448032.3083564.4528008.1635532.6879319-.0409852.0665516-.0970016.122568-.1635532.1635531z"
        fill="#FFFFFF"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default PlayIcon
