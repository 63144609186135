import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styles from './LinkWithArrow.module.scss'
import ArrowIcon from '../icons/ArrowIcon'

const LinkWithArrow = ({ linkText, linkTo, className }) => {
  return (
    <p className={`h6`}>
      <Link to={linkTo} className={`${className}`} activeStyle={{display: 'none'}}>
        {linkText}
        <i className={`inlineIcon ${styles.icon}`}>
          <ArrowIcon />
        </i>
      </Link>
    </p>
  )
}

LinkWithArrow.propTypes = {
  children: PropTypes.node,
  linkTo: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default LinkWithArrow
