import { Link } from 'gatsby'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import CustomImg from '../global/CustomImg'
import styles from './NextSectionCard.module.scss'

gsap.registerPlugin(ScrollTrigger)

const NextSectionCard = ({
  sectionDate,
  sectionTitle,
  sectionImage,
  sectionDesc,
  sectionSlug,
  chapterSlug,
  sectionCode,
  addToTextPositions,
  setActiveDot,
  index,
}) => {
  // Add a namespace so that we don't have duplicate ids
  const prefix = `sec${sectionCode}`

  // Create refs and state
  const sectionCardRef = useRef(null)
  const textRef = useRef(null)

  // Set default values and add Tweens to the Timelines
  useEffect(() => {
    const inTl = gsap.timeline({
      scrollTrigger: {
        id: `next-section-container`,
        trigger: sectionCardRef.current,
        start: 'top bottom-=20%',
        end: 'bottom bottom',
        toggleActions: 'play none none reverse',
        pinSpacing: false,
        scrub: 1,
        // markers: true,
      },
    })

    // All gsap.set() and tweens need to be reset when breakpoints change.
    // Mobile and Portrait Animations
    ScrollTrigger.matchMedia({
      '(max-aspect-ratio: 1/1)': function () {
        // Set default values
        gsap.set(sectionCardRef.current, { opacity: 0 })

        // Fade in the whole card
        inTl
          .clear() // make sure the timeline is empty when matchMedia changes
          .to(sectionCardRef.current, {
            opacity: 1,
            duration: 1,
            ease: 'power1.out',
          })
      },
    })

    // Landscape and Desktop animation
    ScrollTrigger.matchMedia({
      '(min-aspect-ratio: 1/1)': function () {
        // Set default values
        gsap.set(sectionCardRef.current, { opacity: 0 })

        // Fade in the whole card
        inTl
          .clear() // make sure the timeline is empty when matchMedia changes
          .to(sectionCardRef.current, {
            opacity: 1,
            duration: 0.5,
            ease: 'power1.out',
          })
      },
    })

    // Cleanup the timeline on unmount
    return () => {
      inTl.kill()
    }
    // I might need add something like const scrollMatchMedia = ScrollTrigger.matchMedia() as a dependency to update when the media query changes, but it seems to be working correctly
  }, [sectionCardRef])

  return (
    <section id={sectionSlug} className={`${styles.sectionCardContainer}`}>
      <div ref={sectionCardRef} className={`${styles.sectionCard}`}>
        <div id={`${prefix}-text`} ref={textRef}>
          <div id={`${prefix}-image`} className={`${styles.imageWrapper}`}>
            <CustomImg localFile={sectionImage.localFile} alt={''} />
          </div>
          <div className={`${styles.textContainer}`}>
            <p id={`${prefix}-date`} className={`h4 textColorWhite`}>
              {sectionDate}
            </p>
            <p
              id={`${prefix}-title`}
              className={`h3 ${styles.sectionTitle} mb12 textColorWhite`}
            >
              {sectionTitle}
            </p>
            <div id={`${prefix}-description`}>
              <p className={`mb24 textColorWhite`}>{sectionDesc}</p>
              <Link
                to={`/${chapterSlug}/${sectionSlug}/`}
                className={`button`}
                style={{ display: 'inline-block' }}
              >
                <span className={`h5`}>Explore</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

NextSectionCard.propTypes = {
  sectionDate: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  sectionDesc: PropTypes.string.isRequired,
  sectionSlug: PropTypes.string.isRequired,
  chapterSlug: PropTypes.string.isRequired,
  sectionCode: PropTypes.string.isRequired,
  // addToTextPositions: PropTypes.func.isRequired,
  // setActiveDot: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
}

export default NextSectionCard
