import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
// import Img from 'gatsby-image'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import GetImgCaptionFormat from '../../utils/GetImgCaptionFormat'
import CustomImg from '../global/CustomImg'
import Lightbox from '../global/Lightbox'
import InfoIcon from '../icons/InfoIcon'
import styles from './HeroImageText.module.scss'

gsap.registerPlugin(ScrollTrigger)

const HeroImageText = ({ accent, heroImage, header, headerStyle, textTop }) => {
  const [lightboxVisible, setLightboxVisible] = useState(false)
  const hasHeader = header !== null && header !== ''
  let captionCreditData
  if (heroImage !== null && typeof heroImage !== 'undefined') {
    captionCreditData = GetImgCaptionFormat(heroImage.caption)
  }

  const textRef = useRef(null)

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        id: `section`,
        trigger: textRef.current,
        start: 'top bottom-=20%',
        end: 'top center',
        toggleActions: 'play none none reverse',
        scrub: 1,
        // markers: true,
      },
    })

    // ScrollTrigger.refresh()

    tl.fromTo(
      textRef.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,

        duration: 1,
      }
    )
    // Cleanup the timeline on unmount
    return () => {
      tl.kill()
    }
  }, [textRef])

  return (
    <section className={`${styles.sectionHero} ${textTop && styles.reverse}`}>
      {heroImage !== null && typeof heroImage !== 'undefined' && (
        <Fragment>
          <div
            className={`${styles.heroImageWrapper} ${accent} 
          ${hasHeader && (textTop ? 'imgTopFade' : 'imgBottomFade')}`}
          >
            <CustomImg
              className={`${hasHeader && 'imgMultiplyAccent'}`}
              localFile={heroImage.localFile}
              alt={heroImage.alternativeText}
            />
          </div>

          <button
            className={`${styles.captionButton} textRight ${
              textTop && styles.bottom
            }`}
            onClick={() => setLightboxVisible(true)}
            aria-label="View fullscreen"
          >
            <InfoIcon />
          </button>

          {!hasHeader && (
            // If there's no heading text, show the caption
            <div className={`fullWidthCaptionContainer`}>
              <p className={`figcaption pl8 accentBar ${accent} `}>
                {captionCreditData.caption}{' '}
                <span className={`imageCredit`}>
                  {captionCreditData.credit}
                </span>
              </p>
            </div>
          )}

          <Lightbox
            isVisible={lightboxVisible}
            setIsVisible={setLightboxVisible}
          >
            <CustomImg
              localFile={heroImage.localFile}
              alt={heroImage.alternativeText}
              className={`lightboxImg`}
            />
            <p className={`h6 lightboxCaption`}>
              {captionCreditData.caption}{' '}
              <span className={`imageCredit lightboxCaption`}>
                {captionCreditData.credit}
              </span>
            </p>
          </Lightbox>
        </Fragment>
      )}

      {/* Heading Text  */}
      {hasHeader && (
        <div className={`pageMargin1Col`} ref={textRef}>
          <ReactMarkdown
            source={`## ${header}`}
            allowedTypes={['heading', 'text', 'strong']}
            className={`zIndex3 ${styles.header} ${headerStyle} ${accent}`}
          />
        </div>
      )}
    </section>
  )
}

HeroImageText.propTypes = {
  accent: PropTypes.string,
  heroImage: PropTypes.object,
  header: PropTypes.string,
  headerStyle: PropTypes.string,
  textTop: PropTypes.bool,
}

export default HeroImageText
