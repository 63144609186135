import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import CustomImg from '../global/CustomImg'
import Lightbox from '../global/Lightbox'
import ShareLinkButton from '../global/ShareLinkButton'
import styles from './PeopleCards.module.scss'

const PeopleCards = ({ accent, people, shareButton }) => {
  const { name, illustration, body, person_id } = people[0]

  // const { aspectRatio } = illustration.localFile.childImageSharp.fluid

  // Lightbox state
  const [lightboxVisible, setLightboxVisible] = useState(null)

  return (
    <section className={`relPos`}>
      <div className={` ${styles.peopleCardGrid}`}>
        <div className={`${styles.imageContainer}`}>
          <div
            className={`${styles.cardWrapper} `}
            tabIndex="0"
            role="button"
            aria-label="View fullscreen"
            onClick={() => {
              setLightboxVisible(true)
            }}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                setLightboxVisible(true)
              }
            }}
          >
            <CustomImg
              localFile={illustration.localFile}
              alt={illustration.alternativeText}
              className={`${styles.singleCard}`}
              // style={{ overflow: 'visible' }}
            />
          </div>
        </div>

        <div className={`${styles.textContainer}`}>
          <p className={`h3`}>{name}</p>
          <div className={`${styles.cardInfoBody}`}>
            <ReactMarkdown
              source={body}
              allowedTypes={[
                'text',
                'paragraph',
                'break',
                'emphasis',
                'strong',
                'list',
                'listItem',
                'blockquote',
              ]}
            />
          </div>
          {shareButton && (
            <ShareLinkButton
              className={`${styles.shareBtn}`}
              linkPage={`profile-cards/${person_id}/`}
            />
          )}
        </div>
      </div>
      <Lightbox isVisible={lightboxVisible} setIsVisible={setLightboxVisible}>
        <CustomImg
          localFile={illustration.localFile}
          alt={illustration.alternativeText}
          className={`lightboxImg`}
        />
      </Lightbox>
    </section>
  )
}

export default PeopleCards
