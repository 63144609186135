import Img from 'gatsby-image'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import PropTypes from 'prop-types'
import React from 'react'

gsap.registerPlugin(ScrollTrigger)

const IllusImg = ({
  localFile,
  alt,
  className,
  style,
  loading,
  imageLoaded,
}) => {
  if (
    localFile.childImageSharp !== null &&
    typeof localFile.childImageSharp !== 'undefined'
  ) {
    // JPEGs and PNGs
    return (
      <Img
        fluid={localFile.childImageSharp.fluid}
        alt={alt}
        className={className}
        style={style}
        loading={loading}
        onLoad={() => {
          // console.log('layer loaded')
          imageLoaded()
        }}
      />
    )
  } else {
    console.error('No Illustration Layer')
  }
}

IllusImg.propTypes = {
  localFile: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  loading: PropTypes.string,
  loadFunc: PropTypes.func,
}

export default IllusImg
