import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import GetImgCaptionFormat from '../../utils/GetImgCaptionFormat'
import CustomImg from '../global/CustomImg'
import VideoPlayer from '../global/VideoPlayer'
import styles from './VideoClip.module.scss'

gsap.registerPlugin(ScrollTrigger)

const VideoClip = ({ accent, paragraph, image, videoUrl, ratio }) => {
  const captionCreditData = GetImgCaptionFormat(image.caption)

  const textRef = useRef(null)

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        id: `section`,
        trigger: textRef.current,
        start: 'top bottom-=10%',
        end: 'top bottom-=40%',
        toggleActions: 'play none none reverse',
        scrub: 1,
        // markers: true,
      },
    })
    // ScrollTrigger.refresh()

    tl.fromTo(
      textRef.current,
      {
        opacity: 0,
        // y: '1rem'
      },
      {
        opacity: 1,
        // y: 0,
        duration: 1,
      }
    )
    // Cleanup the timeline on unmount
    return () => {
      tl.kill()
    }
  }, [textRef])

  return (
    <section>
      {/* <div className={`pageMargin1Col sectionGrid1fr sectionBottomMarginBig`}> */}

      {/* <div>
          <ReactMarkdown
            source={paragraph}
            allowedTypes={[
              'text',
              'paragraph',
              'break',
              'emphasis',
              'strong',
              'blockquote',
            ]}
          />
        </div> */}

      {/* Video and background image  */}
      <div className={`${styles.videoContainer}`}>
        <div className={`pageMarginRatio`}>
          <VideoPlayer url={videoUrl} coverImage={image} />
        </div>
        <div className={`blurredImageWrapper`}>
          <div className={`blurredImage`}>
            <CustomImg
              localFile={image.localFile}
              alt={image.alternativeText}
            />
          </div>
        </div>
      </div>

      {/* Caption  */}
      <div className={`fullWidthCaptionContainer`}>
        <blockquote ref={textRef}>
          <span className={`h4 ${accent} accentText`}>
            {captionCreditData.caption}{' '}
          </span>
          <br />
          <cite className={`imageCredit`}>{captionCreditData.credit}</cite>
        </blockquote>
      </div>
      {/* <div className={`${styles.mask}`}></div> */}
    </section>
  )
}

VideoClip.propTypes = {
  accent: PropTypes.string,
  paragraph: PropTypes.string,
  image: PropTypes.object,
}

export default VideoClip
