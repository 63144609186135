import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import GetImgCaptionFormat from '../../utils/GetImgCaptionFormat'
import Halftone2 from '../backgrounds/Halftone2'
import CustomImg from '../global/CustomImg'
import FigCaption from '../global/FigCaption'
import Lightbox from '../global/Lightbox'
import styles from './TextWithImage.module.scss'

gsap.registerPlugin(ScrollTrigger)

const TextWithImage = ({
  accent,
  text,
  image,
  isTransparent,
  isOdd,
  bgImage,
}) => {
  let aspectRatio

  let captionCreditData = {}
  if (image !== null && typeof image !== 'undefined') {
    captionCreditData = GetImgCaptionFormat(image.caption)
  }
  if (image.localFile.childImageSharp !== null) {
    aspectRatio = image.localFile.childImageSharp.fluid.aspectRatio
  }

  // Lightbox state
  const [lightboxVisible, setLightboxVisible] = useState(null)

  const sectionRef = useRef(null)
  const figureRef = useRef(null)
  const textRef = useRef(null)

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        id: `section`,
        trigger: sectionRef.current,
        start: 'top bottom',
        // start: 'top bottom-=20%',
        end: 'bottom top',
        toggleActions: 'play none none reverse',
        scrub: 1,
      },
    })

    tl.fromTo(sectionRef.current, { opacity: 0 }, { opacity: 1, duration: 0.5 })
      .fromTo(figureRef.current, { y: '6rem' }, { y: '-4rem', duration: 1 }, 0)
      .fromTo(textRef.current, { y: '2rem' }, { y: '-2rem', duration: 1 }, 0)
      .to(sectionRef.current, { opacity: 0 }, '-=.25')

    // Cleanup the timeline on unmount
    return () => {
      tl.kill()
    }
  }, [sectionRef, figureRef, textRef])

  return (
    <section className={`relPos`}>
      <div className={`relPos`} ref={sectionRef}>
        <div
          className={` 
            ${styles.gridContainer} ${
            isOdd && styles.gridContainerOdd
          } ${accent}`}
        >
          {/* Text  */}
          <div className={`${styles.textWrapper}`} ref={textRef}>
            <ReactMarkdown
              source={text}
              allowedTypes={[
                'text',
                'paragraph',
                'break',
                'emphasis',
                'strong',
                'blockquote',
                'link',
              ]}
            />
          </div>

          {/* Image  */}
          <div
            className={`${styles.imageContainer} ${
              isOdd && styles.imagePaddingOdd
            }`}
          >
            {image !== null && typeof image !== 'undefined' ? (
              <figure className={`${styles.figureLayout}`} ref={figureRef}>
                <div
                  className={`${styles.imageWrapper} `}
                  tabIndex="0"
                  role="button"
                  onClick={() => {
                    setLightboxVisible(true)
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      setLightboxVisible(true)
                    }
                  }}
                  aria-label="View fullscreen"
                  style={{
                    width: `calc(${
                      aspectRatio < 1 && !isTransparent
                        ? 70 * aspectRatio + 'vh'
                        : '100%'
                    })`,
                    maxWidth: '100%',
                  }}
                >
                  <CustomImg
                    localFile={image.localFile}
                    alt={image.alternativeText}
                    className={`${styles.noCrop} `}
                    style={
                      !isTransparent
                        ? { overflow: 'visible', maxHeight: '70vh' }
                        : {}
                    }
                  />
                </div>
                {/* overflow: visible makes the image's shadow visible */}

                <FigCaption
                  accent={accent}
                  caption={captionCreditData}
                  style={{
                    width: `calc(${
                      aspectRatio < 1 && !isTransparent
                        ? 70 * aspectRatio + 'vh'
                        : '100%'
                    })`,
                  }}
                />
              </figure>
            ) : (
              <p>No image provided</p>
            )}
          </div>
        </div>

        {/* Tinted Background Image  */}
        {!isTransparent && (
          <div
            className={`${styles.tintedImageWrapper} ${accent} ${
              isOdd && styles.tintedImageWrapperOdd
            }`}
          >
            <div
              className={`${styles.tintedImageCrop} ${
                isOdd && styles.maskRight
              }`}
            >
              <CustomImg
                localFile={
                  bgImage === null ? image.localFile : bgImage.localFile
                }
                alt={
                  bgImage === null
                    ? image.alternativeText
                    : bgImage.alternativeText
                }
                className={`${styles.tintedImage}`}
              />
            </div>
          </div>
        )}

        {/* Halftone Pattern  */}
        <div
          className={`${
            isOdd ? styles.halftoneWrapperOdd : styles.halftoneWrapper
          }`}
        >
          <Halftone2 />
        </div>
      </div>
      {/* Lightbox  */}
      <Lightbox isVisible={lightboxVisible} setIsVisible={setLightboxVisible}>
        <CustomImg
          localFile={image.localFile}
          alt={image.alternativeText}
          className={`lightboxImg`}
        />
        <p className={`h6 lightboxCaption`}>
          {captionCreditData.caption}{' '}
          <span className={`imageCredit lightboxCaption`}>
            {captionCreditData.credit}
          </span>
        </p>
      </Lightbox>
    </section>
  )
}

TextWithImage.propTypes = {
  accent: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.object,
  containImage: PropTypes.bool,
  isOdd: PropTypes.bool,
}

export default TextWithImage
