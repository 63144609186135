let imgCaptionData = {};
const GetImgCaptionFormat = (caption) => {
	let imgCaption = caption;
	let imgCredit = ''
  let creditPos = caption.search(/Credit:/g);
  if (creditPos !== -1) {
    imgCaption = caption.slice(0, creditPos).trim();
    imgCredit = caption.slice(creditPos+7).trim();
  }
  return (imgCaptionData = {caption: imgCaption, credit: imgCredit})
};
export { imgCaptionData };
export default GetImgCaptionFormat;