import React from 'react'

const InfoIcon = () => {
  return (
    <svg viewBox="0 0 40 39">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8272 37c9.8772 0 17.8272-7.8663 17.8272-17.5 0-9.6337-7.95-17.5-17.8272-17.5C9.95 2 2 9.8663 2 19.5 2 29.1337 9.95 37 19.8272 37zm0 2c10.9502 0 19.8272-8.7304 19.8272-19.5S30.7774 0 19.8272 0C8.8769 0 0 8.7304 0 19.5S8.877 39 19.8272 39z"
      />
      <path
        d="M21.7476 28.4448h-3.5401v-13.208h3.5401v13.208zM18 11.8188c0-.5289.175-.9643.5249-1.3061.3581-.3418.8423-.5127 1.4526-.5127.6023 0 1.0824.1709 1.4405.5127.358.3418.5371.7772.5371 1.3061 0 .5372-.1831.9766-.5493 1.3184-.3581.3418-.8342.5127-1.4283.5127-.594 0-1.0742-.1709-1.4404-.5127C18.179 12.7954 18 12.356 18 11.8188z"
      />
    </svg>
  )
}

export default InfoIcon
