import React from 'react'

const Halftone2 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 258.12 632.74">
      <g>
        <path
          d="M228.5,3.23a3.74,3.74,0,0,0,4.89-2,3.91,3.91,0,0,0,.26-1.2h-7.44A3.75,3.75,0,0,0,228.5,3.23Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M256.5,7.94a2.62,2.62,0,1,0,1.41,3.42A2.62,2.62,0,0,0,256.5,7.94Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M146.06,0h-7a6.63,6.63,0,0,0,.8.4A7.06,7.06,0,0,0,146.06,0Z"
          fill="#333"
          opacity="0.65"
        />
        <path
          d="M162.59,2.15A6,6,0,1,0,172.1,0h-8A6.1,6.1,0,0,0,162.59,2.15Z"
          fill="#333"
          opacity="0.56"
        />
        <path
          d="M191.81,19.51a4.86,4.86,0,1,0-2.63-6.34A4.86,4.86,0,0,0,191.81,19.51Z"
          fill="#333"
          opacity="0.47"
        />
        <path
          d="M222.68,27a3.73,3.73,0,1,0-4.88,2A3.73,3.73,0,0,0,222.68,27Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M245.79,33.78a2.62,2.62,0,1,0,1.42,3.42A2.61,2.61,0,0,0,245.79,33.78Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M77.16,7.13a9.32,9.32,0,0,0,12.18-5A9.22,9.22,0,0,0,89.92,0H71.54A9.29,9.29,0,0,0,77.16,7.13Z"
          fill="#333"
          opacity="0.82"
        />
        <circle
          cx="106.29"
          cy="9.1"
          r="8.2"
          transform="translate(18 68.98) rotate(-39.04)"
          fill="#333"
          opacity="0.74"
        />
        <path
          d="M129.13,26.24A7.09,7.09,0,1,0,125.3,17,7.1,7.1,0,0,0,129.13,26.24Z"
          fill="#333"
          opacity="0.65"
        />
        <path
          d="M155.12,35.8A6,6,0,1,0,151.89,28,6,6,0,0,0,155.12,35.8Z"
          fill="#333"
          opacity="0.56"
        />
        <path
          d="M181.11,45.35A4.85,4.85,0,1,0,178.48,39,4.84,4.84,0,0,0,181.11,45.35Z"
          fill="#333"
          opacity="0.47"
        />
        <path
          d="M212,52.88a3.74,3.74,0,1,0-4.88,2A3.74,3.74,0,0,0,212,52.88Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M235.09,59.62A2.62,2.62,0,1,0,236.51,63,2.62,2.62,0,0,0,235.09,59.62Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M14.48,13.86A11.54,11.54,0,0,0,30,0H7.8A11.57,11.57,0,0,0,14.48,13.86Z"
          fill="#333"
        />
        <path
          d="M40.47,23.41A10.44,10.44,0,1,0,34.82,9.78,10.43,10.43,0,0,0,40.47,23.41Z"
          fill="#333"
          opacity="0.91"
        />
        <path
          d="M66.46,33a9.32,9.32,0,1,0-5-12.18A9.32,9.32,0,0,0,66.46,33Z"
          fill="#333"
          opacity="0.82"
        />
        <circle
          cx="95.58"
          cy="34.94"
          r="8.2"
          transform="translate(-4.36 54.12) rotate(-30.94)"
          fill="#333"
          opacity="0.74"
        />
        <path
          d="M118.43,52.08a7.09,7.09,0,1,0-3.83-9.26A7.08,7.08,0,0,0,118.43,52.08Z"
          fill="#333"
          opacity="0.65"
        />
        <path
          d="M144.42,61.64a6,6,0,1,0-3.23-7.81A6,6,0,0,0,144.42,61.64Z"
          fill="#333"
          opacity="0.56"
        />
        <path
          d="M170.41,71.19a4.85,4.85,0,1,0-2.63-6.34A4.85,4.85,0,0,0,170.41,71.19Z"
          fill="#333"
          opacity="0.47"
        />
        <path
          d="M201.28,78.72a3.74,3.74,0,1,0-4.89,2A3.74,3.74,0,0,0,201.28,78.72Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M224.39,85.46a2.62,2.62,0,1,0,1.41,3.42A2.62,2.62,0,0,0,224.39,85.46Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M18.88,33.44A11.55,11.55,0,0,0,0,20.89V37.16a11.55,11.55,0,0,0,18.88-3.72Z"
          fill="#333"
        />
        <path
          d="M29.77,49.25a10.44,10.44,0,1,0-5.65-13.63A10.42,10.42,0,0,0,29.77,49.25Z"
          fill="#333"
          opacity="0.91"
        />
        <path
          d="M50.71,46.63a9.32,9.32,0,1,0,12.18-5A9.32,9.32,0,0,0,50.71,46.63Z"
          fill="#333"
          opacity="0.82"
        />
        <path
          d="M77.3,57.64A8.2,8.2,0,1,0,88,53.2,8.2,8.2,0,0,0,77.3,57.64Z"
          fill="#333"
          opacity="0.74"
        />
        <path
          d="M107.73,77.92a7.09,7.09,0,1,0-3.84-9.26A7.09,7.09,0,0,0,107.73,77.92Z"
          fill="#333"
          opacity="0.65"
        />
        <path
          d="M130.48,79.67a6,6,0,1,0,7.81-3.23A6,6,0,0,0,130.48,79.67Z"
          fill="#333"
          opacity="0.56"
        />
        <path
          d="M157.07,90.69a4.86,4.86,0,1,0,6.35-2.63A4.85,4.85,0,0,0,157.07,90.69Z"
          fill="#333"
          opacity="0.47"
        />
        <path
          d="M190.57,104.56a3.73,3.73,0,1,0-4.88,2A3.73,3.73,0,0,0,190.57,104.56Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M213.68,111.3a2.62,2.62,0,1,0,1.42,3.42A2.61,2.61,0,0,0,213.68,111.3Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M8.17,59.29a11.54,11.54,0,0,0-6.25-15.1A12.18,12.18,0,0,0,0,43.59V66.14A11.52,11.52,0,0,0,8.17,59.29Z"
          fill="#333"
        />
        <path
          d="M19.06,75.09a10.44,10.44,0,1,0-5.64-13.63A10.43,10.43,0,0,0,19.06,75.09Z"
          fill="#333"
          opacity="0.91"
        />
        <path
          d="M52.19,67.43a9.32,9.32,0,1,0,5,12.17A9.32,9.32,0,0,0,52.19,67.43Z"
          fill="#333"
          opacity="0.82"
        />
        <circle
          cx="74.18"
          cy="86.62"
          r="8.2"
          transform="translate(-37.67 64.37) rotate(-38.17)"
          fill="#333"
          opacity="0.74"
        />
        <path
          d="M93.19,94.5a7.09,7.09,0,1,0,9.26-3.84A7.09,7.09,0,0,0,93.19,94.5Z"
          fill="#333"
          opacity="0.65"
        />
        <path
          d="M130.81,110.08a6,6,0,1,0-7.8,3.24A6,6,0,0,0,130.81,110.08Z"
          fill="#333"
          opacity="0.56"
        />
        <path
          d="M152.71,113.9a4.85,4.85,0,1,0,2.63,6.34A4.85,4.85,0,0,0,152.71,113.9Z"
          fill="#333"
          opacity="0.47"
        />
        <path
          d="M179.87,130.4a3.74,3.74,0,1,0-4.88,2A3.74,3.74,0,0,0,179.87,130.4Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M203,137.14a2.62,2.62,0,1,0,1.42,3.42A2.62,2.62,0,0,0,203,137.14Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M22,95.29a10.44,10.44,0,1,0-13.64,5.64A10.44,10.44,0,0,0,22,95.29Z"
          fill="#333"
          opacity="0.91"
        />
        <path
          d="M29.3,98.31a9.33,9.33,0,1,0,12.18-5A9.32,9.32,0,0,0,29.3,98.31Z"
          fill="#333"
          opacity="0.82"
        />
        <circle
          cx="63.47"
          cy="112.47"
          r="8.2"
          transform="translate(-56.78 65.38) rotate(-39.17)"
          fill="#333"
          opacity="0.74"
        />
        <circle
          cx="89.03"
          cy="123.05"
          r="7.09"
          transform="translate(-59.8 92.74) rotate(-42.6)"
          fill="#333"
          opacity="0.65"
        />
        <path
          d="M120.11,135.92a6,6,0,1,0-7.8,3.24A6,6,0,0,0,120.11,135.92Z"
          fill="#333"
          opacity="0.56"
        />
        <path
          d="M142,139.74a4.86,4.86,0,1,0,2.63,6.34A4.84,4.84,0,0,0,142,139.74Z"
          fill="#333"
          opacity="0.47"
        />
        <path
          d="M169.17,156.24a3.74,3.74,0,1,0-4.89,2A3.74,3.74,0,0,0,169.17,156.24Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M192.28,163a2.62,2.62,0,1,0,1.41,3.42A2.62,2.62,0,0,0,192.28,163Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M11.29,121.13a10.43,10.43,0,0,0-5.64-13.64A10.35,10.35,0,0,0,0,106.84v20.59A10.43,10.43,0,0,0,11.29,121.13Z"
          fill="#333"
          opacity="0.91"
        />
        <path
          d="M18.6,124.15a9.32,9.32,0,1,0,12.18-5A9.32,9.32,0,0,0,18.6,124.15Z"
          fill="#333"
          opacity="0.82"
        />
        <path
          d="M49.63,145.89a8.2,8.2,0,1,0-4.44-10.72A8.21,8.21,0,0,0,49.63,145.89Z"
          fill="#333"
          opacity="0.74"
        />
        <path
          d="M84.88,151.6a7.09,7.09,0,1,0-9.26,3.84A7.09,7.09,0,0,0,84.88,151.6Z"
          fill="#333"
          opacity="0.65"
        />
        <path
          d="M109.41,161.76A6,6,0,1,0,101.6,165,6,6,0,0,0,109.41,161.76Z"
          fill="#333"
          opacity="0.56"
        />
        <path
          d="M127.59,174.55a4.86,4.86,0,1,0-2.63-6.34A4.86,4.86,0,0,0,127.59,174.55Z"
          fill="#333"
          opacity="0.47"
        />
        <path
          d="M158.46,182.08a3.73,3.73,0,1,0-4.88,2A3.73,3.73,0,0,0,158.46,182.08Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M181.57,188.82a2.62,2.62,0,1,0,1.42,3.42A2.61,2.61,0,0,0,181.57,188.82Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M0,137.79v10.36A10,10,0,0,0,.59,147,10.4,10.4,0,0,0,0,137.79Z"
          fill="#333"
          opacity="0.91"
        />
        <path
          d="M25.12,157.13a9.32,9.32,0,1,0-12.18,5A9.32,9.32,0,0,0,25.12,157.13Z"
          fill="#333"
          opacity="0.82"
        />
        <path
          d="M34.49,161a8.2,8.2,0,1,0,10.72-4.44A8.2,8.2,0,0,0,34.49,161Z"
          fill="#333"
          opacity="0.74"
        />
        <path
          d="M74.18,177.45a7.09,7.09,0,1,0-9.27,3.83A7.1,7.1,0,0,0,74.18,177.45Z"
          fill="#333"
          opacity="0.65"
        />
        <path
          d="M98.7,187.61a6,6,0,1,0-7.8,3.23A6,6,0,0,0,98.7,187.61Z"
          fill="#333"
          opacity="0.56"
        />
        <path
          d="M116.89,200.39a4.85,4.85,0,1,0-2.63-6.34A4.84,4.84,0,0,0,116.89,200.39Z"
          fill="#333"
          opacity="0.47"
        />
        <path
          d="M147.76,207.92a3.74,3.74,0,1,0-4.88,2A3.74,3.74,0,0,0,147.76,207.92Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M170.87,214.66a2.62,2.62,0,1,0,1.42,3.42A2.62,2.62,0,0,0,170.87,214.66Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M14.42,183A9.32,9.32,0,0,0,0,172.11v14.58A9.32,9.32,0,0,0,14.42,183Z"
          fill="#333"
          opacity="0.82"
        />
        <path
          d="M23.78,186.85a8.2,8.2,0,1,0,10.72-4.44A8.22,8.22,0,0,0,23.78,186.85Z"
          fill="#333"
          opacity="0.74"
        />
        <path
          d="M63.47,203.29a7.09,7.09,0,1,0-9.26,3.83A7.1,7.1,0,0,0,63.47,203.29Z"
          fill="#333"
          opacity="0.65"
        />
        <path
          d="M88,213.45a6,6,0,1,0-7.8,3.23A6,6,0,0,0,88,213.45Z"
          fill="#333"
          opacity="0.56"
        />
        <path
          d="M106.18,226.23a4.86,4.86,0,1,0-2.62-6.34A4.86,4.86,0,0,0,106.18,226.23Z"
          fill="#333"
          opacity="0.47"
        />
        <path
          d="M137.06,233.77a3.74,3.74,0,1,0-4.89,2A3.74,3.74,0,0,0,137.06,233.77Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M160.17,240.5a2.62,2.62,0,1,0,1.41,3.42A2.62,2.62,0,0,0,160.17,240.5Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M3.71,208.81A9.32,9.32,0,0,0,0,197.32v15.84A9.24,9.24,0,0,0,3.71,208.81Z"
          fill="#333"
          opacity="0.82"
        />
        <path
          d="M17.52,223.41a8.2,8.2,0,1,0-4.44-10.72A8.21,8.21,0,0,0,17.52,223.41Z"
          fill="#333"
          opacity="0.74"
        />
        <path
          d="M43.51,233a7.09,7.09,0,1,0-3.84-9.26A7.09,7.09,0,0,0,43.51,233Z"
          fill="#333"
          opacity="0.65"
        />
        <path
          d="M77.3,239.29a6,6,0,1,0-7.81,3.23A6,6,0,0,0,77.3,239.29Z"
          fill="#333"
          opacity="0.56"
        />
        <path
          d="M95.48,252.07a4.86,4.86,0,1,0-2.63-6.34A4.85,4.85,0,0,0,95.48,252.07Z"
          fill="#333"
          opacity="0.47"
        />
        <path
          d="M126.35,259.61a3.74,3.74,0,1,0-4.88,2A3.74,3.74,0,0,0,126.35,259.61Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M149.46,266.34a2.62,2.62,0,1,0,1.42,3.43A2.62,2.62,0,0,0,149.46,266.34Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M17.54,244.81a8.2,8.2,0,1,0-10.72,4.44A8.21,8.21,0,0,0,17.54,244.81Z"
          fill="#333"
          opacity="0.74"
        />
        <path
          d="M32.8,258.8A7.09,7.09,0,1,0,29,249.54,7.1,7.1,0,0,0,32.8,258.8Z"
          fill="#333"
          opacity="0.65"
        />
        <path
          d="M66.59,265.13a6,6,0,1,0-7.8,3.23A6,6,0,0,0,66.59,265.13Z"
          fill="#333"
          opacity="0.56"
        />
        <path
          d="M84.78,277.91a4.85,4.85,0,1,0-2.63-6.34A4.85,4.85,0,0,0,84.78,277.91Z"
          fill="#333"
          opacity="0.47"
        />
        <path
          d="M115.65,285.45a3.74,3.74,0,1,0-4.89,2A3.75,3.75,0,0,0,115.65,285.45Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M138.76,292.18a2.62,2.62,0,1,0,1.42,3.43A2.63,2.63,0,0,0,138.76,292.18Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M6.83,270.65a8.19,8.19,0,0,0-4.44-10.72,8.23,8.23,0,0,0-2.39-.6v16.34A8.18,8.18,0,0,0,6.83,270.65Z"
          fill="#333"
          opacity="0.74"
        />
        <path
          d="M18.26,275.38a7.09,7.09,0,1,0,9.27-3.83A7.09,7.09,0,0,0,18.26,275.38Z"
          fill="#333"
          opacity="0.65"
        />
        <path
          d="M55.89,291a6,6,0,1,0-7.8,3.23A6,6,0,0,0,55.89,291Z"
          fill="#333"
          opacity="0.56"
        />
        <path
          d="M74.07,303.75a4.86,4.86,0,1,0-2.62-6.34A4.86,4.86,0,0,0,74.07,303.75Z"
          fill="#333"
          opacity="0.47"
        />
        <path
          d="M105,311.29a3.74,3.74,0,1,0-4.89,2A3.74,3.74,0,0,0,105,311.29Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M128.06,318a2.62,2.62,0,1,0,1.41,3.43A2.63,2.63,0,0,0,128.06,318Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M20.66,306.65a7.09,7.09,0,1,0-9.26,3.83A7.08,7.08,0,0,0,20.66,306.65Z"
          fill="#333"
          opacity="0.65"
        />
        <path
          d="M45.19,316.81A6,6,0,1,0,37.38,320,6,6,0,0,0,45.19,316.81Z"
          fill="#333"
          opacity="0.56"
        />
        <path
          d="M63.37,329.59a4.86,4.86,0,1,0-2.63-6.34A4.85,4.85,0,0,0,63.37,329.59Z"
          fill="#333"
          opacity="0.47"
        />
        <path
          d="M94.24,337.13a3.74,3.74,0,1,0-4.88,2A3.74,3.74,0,0,0,94.24,337.13Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M117.35,343.86a2.63,2.63,0,1,0,1.42,3.43A2.62,2.62,0,0,0,117.35,343.86Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M10,332.49a7.09,7.09,0,0,0-10-8.93V336a7.32,7.32,0,0,0,.69.33A7.1,7.1,0,0,0,10,332.49Z"
          fill="#333"
          opacity="0.65"
        />
        <path
          d="M34.48,342.65a6,6,0,1,0-7.8,3.23A6,6,0,0,0,34.48,342.65Z"
          fill="#333"
          opacity="0.56"
        />
        <path
          d="M52.67,355.43A4.85,4.85,0,1,0,50,349.09,4.85,4.85,0,0,0,52.67,355.43Z"
          fill="#333"
          opacity="0.47"
        />
        <path
          d="M83.54,363a3.74,3.74,0,1,0-4.89,2A3.75,3.75,0,0,0,83.54,363Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M106.65,369.7a2.63,2.63,0,1,0,1.42,3.43A2.63,2.63,0,0,0,106.65,369.7Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M23.78,368.49a6,6,0,1,0-7.8,3.23A6,6,0,0,0,23.78,368.49Z"
          fill="#333"
          opacity="0.56"
        />
        <path
          d="M42,381.27a4.86,4.86,0,1,0-2.62-6.34A4.86,4.86,0,0,0,42,381.27Z"
          fill="#333"
          opacity="0.47"
        />
        <path
          d="M72.84,388.81a3.74,3.74,0,1,0-4.89,2A3.74,3.74,0,0,0,72.84,388.81Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M96,395.54A2.63,2.63,0,1,0,97.36,399,2.63,2.63,0,0,0,96,395.54Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M13.08,394.33a6,6,0,1,0-7.81,3.23A6,6,0,0,0,13.08,394.33Z"
          fill="#333"
          opacity="0.56"
        />
        <path
          d="M31.26,407.11a4.86,4.86,0,1,0-2.63-6.34A4.85,4.85,0,0,0,31.26,407.11Z"
          fill="#333"
          opacity="0.47"
        />
        <path
          d="M62.13,414.65a3.74,3.74,0,1,0-4.88,2A3.74,3.74,0,0,0,62.13,414.65Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M85.24,421.38a2.63,2.63,0,1,0,1.42,3.43A2.62,2.62,0,0,0,85.24,421.38Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M2.37,420.17A6,6,0,0,0,0,412.81V423A5.84,5.84,0,0,0,2.37,420.17Z"
          fill="#333"
          opacity="0.56"
        />
        <path
          d="M20.56,433a4.86,4.86,0,1,0-2.63-6.35A4.86,4.86,0,0,0,20.56,433Z"
          fill="#333"
          opacity="0.47"
        />
        <path
          d="M51.43,440.49a3.74,3.74,0,1,0-4.89,2A3.75,3.75,0,0,0,51.43,440.49Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M74.54,447.22A2.63,2.63,0,1,0,76,450.65,2.63,2.63,0,0,0,74.54,447.22Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M13.57,449.82a4.86,4.86,0,1,0,2.63,6.35A4.85,4.85,0,0,0,13.57,449.82Z"
          fill="#333"
          opacity="0.47"
        />
        <path
          d="M40.73,466.33a3.74,3.74,0,1,0-4.89,2A3.74,3.74,0,0,0,40.73,466.33Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M63.83,473.06a2.62,2.62,0,1,0,1.42,3.43A2.62,2.62,0,0,0,63.83,473.06Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M5.49,482a4.86,4.86,0,0,0-2.62-6.35A4.91,4.91,0,0,0,0,475.4v9.49A4.84,4.84,0,0,0,5.49,482Z"
          fill="#333"
          opacity="0.47"
        />
        <path
          d="M30,492.17a3.74,3.74,0,1,0-4.88,2A3.74,3.74,0,0,0,30,492.17Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M53.13,498.9a2.63,2.63,0,1,0,1.42,3.43A2.62,2.62,0,0,0,53.13,498.9Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M19.32,518a3.74,3.74,0,1,0-4.89,2A3.75,3.75,0,0,0,19.32,518Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M42.43,524.74a2.63,2.63,0,1,0,1.42,3.43A2.63,2.63,0,0,0,42.43,524.74Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M8.62,543.85a3.74,3.74,0,1,0-4.89,2A3.74,3.74,0,0,0,8.62,543.85Z"
          fill="#333"
          opacity="0.39"
        />
        <path
          d="M31.72,550.58A2.62,2.62,0,1,0,33.14,554,2.62,2.62,0,0,0,31.72,550.58Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M21,576.42a2.63,2.63,0,1,0,1.42,3.43A2.62,2.62,0,0,0,21,576.42Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M10.32,602.26a2.63,2.63,0,1,0,1.42,3.43A2.63,2.63,0,0,0,10.32,602.26Z"
          fill="#333"
          opacity="0.3"
        />
        <path
          d="M1,631.53a2.61,2.61,0,0,0-1-3.22v4.43A2.55,2.55,0,0,0,1,631.53Z"
          fill="#333"
          opacity="0.3"
        />
      </g>
    </svg>
  )
}

export default Halftone2
